<template>
    <div class="step-select-users flex-row" :class="`type-${options.type.toLowerCase().replace(/_/g, '-')}`">
        <div class="left flex">
            <div v-if="$store.getters.isMobile && selectedUser" class="introducing-user-mobile flex-row flex-wrap">
                <PhotoAndLastSeen
                    :class="{
                        male: selectedUser.gender === 0,
                        female: selectedUser.gender === 1,
                    }"
                    class="no-touch display-table m-a flex-wrap"
                    :lastSignInAt="selectedUser.last_sign_in_at"
                    :profile="((selectedUser.photos || [])[0] || {}).url"
                    :userId="selectedUser.id"
                />
                <SimpleProfile :user="selectedUser" :showName="true" />
            </div>
            <UsersList
                class="flex-fill"
                :filters="chat.$$filters || defaultFilters()"
                :filterChanged="showSuperFilter && filterChanged"
                :searchValue="searchValue"
                :selectedTabFriends="options.type"
                :isSelectedUser="isSelectedUser"
                :justAsSelector="true"
                :vbRecommend="{ chat, limit: options.limit }"
                @onClickFilters="onClickFilters"
                @onClickUser="onClickUser"
            />
        </div>
        <IntroducingUser
            v-if="selectedUser && !$store.getters.isMobile"
            :user="selectedUser"
            @closeIntroducingUser="closeIntroducingUser"
        />
        <FriendsFilter
            v-if="!selectedUser && !$store.getters.isMobile && options.type !== 'SUPER_SEARCH'"
            :filters="chat.$$filters || defaultFilters()"
            :selectedTab="options.type"
        />
        <SuperFilter
            v-else-if="!selectedUser && !$store.getters.isMobile && options.type === 'SUPER_SEARCH'"
            :filterChanged.sync="filterChanged"
            :filters="chat.$$filters || defaultFilters()"
        />
    </div>
</template>

<script>
import * as helpers from '@/store/data/helpers'
import UsersList from '@/routes/chat/friends/UsersList'
import IntroducingUser from '@/routes/chat/introducing-user/IntroducingUser'

export default {
    name: 'StepSelectUsers',
    components: {
        UsersList,
        IntroducingUser,
    },
    props: ['options'],
    data: () => ({
        selectedUser: null,
        searchValue: null,
        filterChanged: false,
    }),
    computed: {
        showSuperFilter() {
            return this.$store.getters.showFriendsFilter && this.$store.getters.selectedTabFriends === 'SUPER_SEARCH'
        },
        applyingFilters() {
            return helpers.applyingFilters(this.filters)
        },
        currentStep() {
            return this.steps[this.currentStepIdx]
        },
        chat() {
            return this.$store.getters.chat || {}
        },
        selectedTabFriendsCount() {
            const counts = this.$store.getters.tabCounts

            switch (this.options.type) {
                case 'FRIENDS':
                    return counts.numFriends
                case 'RECOMMENDS':
                    return counts.numRecommends
                case 'SUPER_SEARCH':
                    return counts.numRecommendsFromDB
                default:
                    return null
            }
        },
    },
    methods: {
        isSelectedUser(user) {
            return user.id === (this.selectedUser || {}).id
        },
        onSearch(val) {
            this.searchValue = val
        },
        onSelectSort(sort) {
            this.$store.commit('setSort', sort)
        },
        async onClickUser(user) {
            this.selectedUser = await this.$store.dispatch('loadUserDetail', { userId: user.id })
            // console.log('105:', this.selectedUser)
        },
        onClickFilters() {
            this.selectedUser = null
        },
        defaultFilters() {
            // This is just for preventing javascript errors,
            // once chat.$$filters is set, this won't be used anymore.
            return helpers.getDefaultFilters(this.chat.user.gender, false)
        },
        closeIntroducingUser() {
            this.selectedUser = null
        },
    },
}
</script>
