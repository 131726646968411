<template>
    <div class="step-example">
        <img v-if="!$store.getters.isMobile" :src="example[type]" />
        <img class="mobile-img" v-else :src="exampleMoblie" alt="" />
        <transition name="slide-up">
            <div v-if="showQuiz" class="overlay">
                <div class="popup-bottom">
                    <div class="pb-header" v-html="$translate('QUIZ')" />
                    <div class="pb-layout">
                        <div class="pb-quiz">
                            <div class="pb-title" v-html="$translate('PB_SPREAD_PROFILE_TITLE')" />
                        </div>
                        <div class="buttons">
                            <button
                                class="btn btn-brd f-14"
                                :class="{ primary: selectedIdx === idx }"
                                @click="onClickButton(idx)"
                                :key="idx"
                                v-for="idx in [0, 1]"
                                v-html="$translate(`PB_SPREAD_PROFILE_QUIZ_ANSWER_${idx}_${type}`)"
                            />
                        </div>
                    </div>
                </div>
            </div>
        </transition>
    </div>
</template>

<script>
import exampleFriends from '@/assets/images/spread_profile_example_friends.jpg'
import exampleRecommends from '@/assets/images/spread_profile_example_recommends.jpg'
import exampleSuperSearch from '@/assets/images/spread_profile_example_super_search.jpg'
import exampleSuperSearchForMobile from '@/assets/images/spread_profile_example_recommends_mobile.png'

export default {
    name: 'StepExample',
    props: ['showQuiz', 'currentStepIdx', 'type'],
    data: () => ({
        selectedIdx: null,
    }),
    methods: {
        onClickButton(idx) {
            this.selectedIdx = idx

            if (idx === 0) {
                this.$toast.success('QUIZ_CORRECT_ANSWER')
                window.localStorage.setItem('hasSeenProfileSpreadExample', true)
                setTimeout(() => {
                    this.$emit('update:currentStepIdx', this.currentStepIdx + 1)
                }, 150)
                return
            }

            if (idx === 1) {
                this.$toast.error('QUIZ_WRONG_ANSWER')
            }
        },
    },
    computed: {
        example() {
            return {
                FRIENDS: exampleFriends,
                RECOMMENDS: exampleRecommends,
                SUPER_SEARCH: exampleSuperSearch,
            }
        },
        exampleMoblie() {
            return exampleSuperSearchForMobile
        },
    },
}
</script>
