<template>
    <div class="modal-spread-profile flex">
        <Loading :loading="loading" />
        <SnackbarProfile :bind="showSbProfile" :user="user" />
        <div class="head flex-row flex-between items-center flex-wrap">
            <i
                v-if="$store.getters.isMobile"
                class="zmdi zmdi-chevron-left"
                :class="currentStep.buttons[0].disabled"
                @click="currentStep.buttons[0].handler"
            />
            <div style="padding-left: 24vw" class="flex-fill flex-row items-center">
                <div class="title" v-html="$translate(`SPREAD_PROFILE_TITLE`)" />
            </div>
            <i v-if="!$store.getters.isMobile" class="zmdi zmdi-close" @click="$emit('close')" />
        </div>
        <div
            v-if="currentStep && !$store.getters.isMobile"
            class="step-desc flex-wrap"
            @mousemove="checkingSnackbarProfile"
            @mouseleave="showSbProfile = false"
            v-html="currentStep.desc"
        />
        <div class="body flex-row flex-fill" v-if="currentStep">
            <StepExample
                :type="options.type"
                :showQuiz="showQuiz"
                :currentStepIdx.sync="currentStepIdx"
                v-show="currentStep.key === 'EXAMPLE'"
            />
            <StepSelectUsers :type="options.type" :options="options" v-show="currentStep.key === 'SELECT_USERS'" />
            <StepPopulateTemplateRecommends
                @onPopulateTemplatePayload="onPopulateTemplatePayload"
                :options="options"
                v-show="currentStep.key === 'POPULATE_TEMPLATE' && options.type !== 'FRIENDS'"
            />
        </div>
        <div v-if="!$store.getters.isMobile" class="buttons bottom flex-wrap">
            <div class="flex-row">
                <button
                    class="btn"
                    :class="[button.class, button.disabled]"
                    @click="button.handler"
                    :key="idx"
                    v-for="(button, idx) in (currentStep || {}).buttons"
                    v-html="$translate(button.label)"
                />
            </div>
        </div>
        <div v-else class="bottom-btn">
            <button
                :class="currentStep.buttons[1].disabled"
                @click="currentStep.buttons[1].handler"
                v-html="$translate(currentStep.buttons[1].label)"
            />
        </div>
    </div>
</template>

<script>
import chatService from '@/services/chat'
import commonService from '@/services/common'
import friendService from '@/services/friend'
import SnackbarProfile from './SnackbarProfile'
import StepExample from './StepExample'
import StepSelectUsers from './StepSelectUsers'
import StepPopulateTemplateRecommends from './StepPopulateTemplateRecommends'

export default {
    name: 'ModalSpreadProfile',
    components: {
        SnackbarProfile,
        StepExample,
        StepSelectUsers,
        StepPopulateTemplateRecommends,
    },
    props: ['options'],
    data: () => ({
        payload: {
            users: null,
            messages: null,
        },
        showQuiz: false,
        showConfirmPopupBottom: false,
        currentStepIdx: 0,
        requiredMessageLength: 10,
        showSbProfile: false,
        loading: false,
    }),
    computed: {
        currentStep() {
            return this.steps[this.currentStepIdx]
        },
        wrongNumUsers() {
            return this.numSelectedUsers === 0 ? 'disabled' : ''
        },
        incompleteTemplate() {
            if (this.options.type === 'FRIENDS') {
                return !this.payload.messages ||
                    this.payload.messages.length === 0 ||
                    !this.hasPhotoMessages ||
                    !this.hasEnoughTextMessages
                    ? 'disabled'
                    : ''
            }

            return !this.payload.message || !this.payload.photo2_id ? 'disabled' : ''
        },
        chat() {
            return this.$store.getters.chat || {}
        },
        selectedUsers() {
            let users
            if (this.options.type === 'FRIENDS') users = (this.$store.getters.friends || []).map(friend => friend.user)
            if (this.options.type === 'RECOMMENDS') users = this.chat.$$recommends || []
            if (this.options.type === 'SUPER_SEARCH') users = this.chat.$$recommendsFromDB || []

            // eslint-disable-next-line vue/no-side-effects-in-computed-properties
            this.payload.users = users.filter(user => user.$$selected)
            return this.payload.users
        },
        numSelectedUsers() {
            return (this.selectedUsers || []).length
        },
        user() {
            return this.options.user
        },
        agent() {
            return this.$store.getters.agent
        },
        seen() {
            return window.localStorage.getItem('hasSeenProfileSpreadExample')
        },
        hasPhotoMessages() {
            return (this.payload.messages || []).filter(m => m.mtype.includes('photo')).length === 1
        },
        hasEnoughTextMessages() {
            return (
                (this.payload.messages || [])
                    .filter(m => m.mtype === 'text')
                    .map(m => m.content)
                    .join('').length >= this.requiredMessageLength
            )
        },
        steps() {
            const steps = [
                {
                    key: 'EXAMPLE',
                    desc: this.$translate(`SPREAD_PROFILE_EXAMPLE_${this.options.type}`),
                    buttons: [
                        { label: 'PREV', class: 'btn-default', handler: () => this.back() },
                        {
                            label: this.$store.getters.isMobile
                                ? 'SPREAD_PROFILE_CHECKED_EXAMPLE_MOBILE'
                                : 'SPREAD_PROFILE_CHECKED_EXAMPLE',
                            class: 'btn-primary',
                            handler: () => this.onConfirmExample(),
                        },
                    ],
                },
                {
                    key: 'SELECT_USERS',
                    desc: this.$translate(`SPREAD_PROFILE_SELECT_USERS_${this.options.type}`).replace(
                        /%s/,
                        this.$nameOrNick(this.user)
                    ),
                    buttons: [
                        {
                            label: 'PREV',
                            class: 'btn-default',
                            handler: () => this.back(),
                        },
                        {
                            label: 'NEXT',
                            class: 'btn-primary',
                            disabled: this.wrongNumUsers,
                            handler: () => this.onConfirmUsers(),
                        },
                    ],
                },
                {
                    key: 'POPULATE_TEMPLATE',
                    desc: this.$translate(`SPREAD_PROFILE_POPULATE_TEMPLATE_${this.options.type}`)
                        .replace(/%s/, this.numSelectedUsers)
                        .replace(/%s/, this.$nameOrNick(this.user)),
                    buttons: [
                        { label: 'PREV', class: 'btn-default', handler: () => this.back() },
                        {
                            label: this.options.type === 'FRIENDS' ? 'SEND' : 'RECOMMEND',
                            class: 'btn-primary',
                            disabled: this.incompleteTemplate,
                            handler: () => this.send(),
                        },
                    ],
                },
            ]

            // 한 번 해봤으면 EXAMPLE은 보여줄 필요 X
            return !this.seen ? steps : [steps[1], steps[2]]
        },
    },
    mounted() {
        const init = async () => {
            const data = await commonService.properties({ key: 'spread_profile_limit' })
            if ((data || []).length === 0) return

            this.limit = this.$mustParse(data[0].value)
        }
        init()
    },
    beforeDestroy() {
        // this.selectedUsers.forEach(user => user.$$selected = false)
    },
    methods: {
        async onSpreadProfileConfirmed() {
            if (this.loading) {
                return
            }

            this.loading = true
            this.payload.users = this.selectedUsers.filter(user => user.$$selected)
            const payload = this.payload
            payload.user_ids = payload.users.map(u => u.id)
            delete payload.users

            let message
            try {
                if (this.options.type === 'FRIENDS') {
                    await chatService.spreadProfile(this.$store.getters.chat.id, payload)
                    this.$store.commit('markAsIntroducedTo', payload.user_ids)
                    message = this.$translate('SPREAD_PROFILE_SUCCESS')
                        .replace(/%s/, this.$nameOrNick(this.user))
                        .replace(/%s/, payload.user_ids.length)
                } else {
                    const data = await friendService.requestMulti(this.payload)
                    this.$store.commit('markAsIntroducedTo', this.payload.user_ids)
                    message = data.msg
                }
                this.$toast.success(message)
                this.$emit('close')
                this.selectedUsers.forEach(user => {
                    user.$$selected = false
                })
            } catch (e) {
                this.$toast.error(e.data)
            } finally {
                this.loading = false
            }
            this.$store.dispatch('loadBadges')
        },
        onPopulateTemplatePayload(payload) {
            this.payload = payload
        },
        onConfirmUsers() {
            if (this.wrongNumUsers) {
                this.$toast.error(this.$translate('ERROR_SPREAD_PROFILE_NUM_USERS'))
                return
            }
            this.currentStepIdx++
        },
        onConfirmExample() {
            this.showQuiz = true
        },
        send() {
            if (this.options.type === 'FRIENDS') {
                if (!this.hasPhotoMessages) {
                    this.$toast.error('ERROR_SPREAD_PROFILE_NO_PHOTO')
                    return
                }

                if (!this.hasEnoughTextMessages) {
                    this.$toast.error(
                        this.$translate('ERROR_SPREAD_PROFILE_NOT_ENOUGH_MESSAGES').replace(
                            /%s/,
                            this.requiredMessageLength
                        )
                    )
                    return
                }

                this.payload.users = this.selectedUsers.filter(user => user.$$selected)
                this.showConfirmPopupBottom = true
                return
            }

            if (this.options.type !== 'FRIENDS') {
                if (this.incompleteTemplate) {
                    this.$toast.error('ERROR_SPREAD_PROFILE_SUGGEST')
                    return
                }

                this.onSpreadProfileConfirmed()
            }
        },
        back() {
            this.currentStepIdx--
            if (!this.steps[this.currentStepIdx]) this.$emit('close')
        },
        onSearch(val) {
            this.searchValue = val
        },
        onSelectSort(sort) {
            this.$store.commit('setSort', sort)
        },
        onSpreadProfileMessage(messages) {
            this.payload.messages = messages
        },
        checkingSnackbarProfile(e) {
            this.showSbProfile = e.target.classList.contains('text-underline')
        },
    },
}
</script>
