<template>
    <transition name="slide-up">
        <div
            class="snackbar-profile snackbar flex-row"
            v-show="show && !$store.getters.isMobile"
            @mouseover="show = true"
            @mouseleave="show = false"
        >
            <div class="img-cover flex-wrap" v-img-cover="user.photos[0].url" />
            <div class="right">
                <SimpleProfile :user="user" :class="'dark'" />
            </div>
        </div>
    </transition>
</template>
<script>
export default {
    name: 'SnackbarProfile',
    props: ['bind', 'user'],
    data: () => ({
        show: false,
    }),
    watch: {
        bind(newVal) {
            this.show = this.bind
        },
    },
}
</script>

<style scoped>
.slide-up-enter-active,
.slide-up-leave-active {
    transition: all 0.2s ease-in-out;
}

.slide-up-enter,
.slide-up-leave-to {
    opacity: 0;
    transform: translateY(10px);
}
</style>
