<template>
    <div class="step-populate-template-recommends flex-row">
        <div class="scroll-area">
            <div class="payload" :class="{ 'flex-row': !$store.getters.isMobile }">
                <div class="left" :class="{ 'p-r-16': !$store.getters.isMobile }">
                    <div class="section-title m-t-24 m-b-16" v-html="$translate('APPEAL_MESSAGE')" />
                    <TextareaWithAutoTexts v-model="payload.message" class="bottom" />

                    <div class="flex-row items-center m-t-12 m-b-24">
                        <div class="badge-tips flex-wrap" v-html="'Tips!'" />
                        <div class="description-small" v-html="$translate('RECOMMEND_HELP')" />
                    </div>

                    <div class="hr" />
                </div>
                <div class="right" v-if="user">
                    <div class="section-title flex-row items-center m-b-16">
                        <span class="flex-wrap" v-html="$translate('CHOOSE_PHOTO')" />
                        <span class="desc" v-html="`(${$translate('CHOOSE_PHOTO_DESC')})`" />
                    </div>
                    <div class="photos">
                        <div
                            class="img-profile"
                            @click="onClickPhoto(photo)"
                            v-lazy:background-image="photo.url"
                            :key="idx"
                            v-for="(photo, idx) in user.photos"
                        >
                            <Checkbox class="white" v-model="photo.checked" />
                        </div>
                    </div>
                    <!--                    <div class="intro" v-html="autoGenerated"/>-->
                    <!--                    <div class="hr-short"/>-->
                    <!--                    <div class="description p-b-24" v-html="$translate('RECOMMEND_AUTO_INTRO_DESC')"/>-->
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'StepPopulateTemplateRecommends',
    props: ['options'],
    data: () => ({
        payload: {
            instant: true,
            recommend_user_id: null,
            message: null,
            type: 'INSTANT',
        },
    }),
    watch: {
        'payload.message'(newVal) {
            this.$emit('onPopulateTemplatePayload', this.payload)
        },
    },
    computed: {
        autoGenerated() {
            return (this.user.auto_intro || []).join('<br><br>').replace(/\n/g, '<br>')
        },
        user() {
            return this.options.user
        },
        disabled() {
            return !this.payload.message || !this.twoPhotosChecked
        },
        twoPhotosChecked() {
            return (this.user.photos || []).filter(p => p.checked).length === 2
        },
    },
    mounted() {
        this.init()
    },
    methods: {
        async init() {
            if (this.user.photos) {
                this.user.photos.forEach((p, idx) => this.$set(p, 'checked', idx < 2))
            }
            this.initSelectedPhotosToPayload()
            this.payload.recommend_user_id = this.user.id
        },
        onClickPhoto(photo) {
            if (!photo.checked && this.twoPhotosChecked) {
                this.$toast.error('CHOOSE_PHOTO_DESC')
                return
            }

            photo.checked = !photo.checked
            this.initSelectedPhotosToPayload()
            this.$emit('onPopulateTemplatePayload', this.payload)
        },
        initSelectedPhotosToPayload() {
            delete this.payload.photo1_id
            delete this.payload.photo2_id
            this.user.photos
                .filter(photo => photo.checked)
                .forEach((photo, idx) => this.$set(this.payload, `photo${idx + 1}_id`, photo.id))
        },
    },
}
</script>
<style scoped lang="scss">
::v-deep .textarea {
    height: 450px !important;
}

.mobile {
    .payload {
        ::v-deep .textarea {
            height: 240px !important;
        }
    }
}
</style>
